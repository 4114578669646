import React from "react";
import styles from "./Solution.module.scss";
import solutionImg from "../../../images/jpycAppsPc.png";

const SolutionSection = () => {
  return (
    <section className={styles.solution}>
      <div className={styles.solutionBoxWrapper}>
        <div>
          <h3 className={styles.personalSolutionText}>Personal solution</h3>
          <div className={styles.personalSolutionBgBox1}></div>
          <h2 className={styles.personalSolutionTitle}>
            デジタルコインから円に変えず、物品の購入ができます
          </h2>
          <p className={styles.personalSolutionSubText}>
            デジタルコインを保有しているユーザーが、欲しいものを入手する際にJPYC Prepaid からギフトへ交換することで、日常生活での利用が可能となります。
          </p>
        </div>
      </div>
      <img src={solutionImg} alt="" />
    </section>
  );
};

export default SolutionSection;
