import React from 'react';
import styles from './IntroductionSection.module.scss'
import jpycTextLogo from '../../../images/jpycPrepaidTextLogo.svg'
import topBgIcon from '../../../images/jpycPrepaidSymbol.svg'
import topJPYCSpSymbol from '../../../images/JPYCSpSymbol.svg'
import clsx from 'clsx'

const IntroductionSection = () => {
  return (
    <section className={styles.introduction}>
      <div className={styles.introductionWrapper}>
        <div className={styles.topLeftBox}>
          <img className={styles.jpycTextLogo} src={jpycTextLogo} alt="jpyc-text-logo" />
          <h1 className={styles.introductionTitle}>
            エンをつなげる
            <br />
            日本円ステーブルコイン
          </h1>
          <p className={clsx([styles.subText, styles.spNone])}>
          バプリックチェーン上のプリペイド型ステーブルコイン「JPYC Prepaid 」を日本円で購入可能
          </p>
          <a href='https://app.jpyc.jp/' target="_blank" className={clsx([styles.buyJpyc, styles.spNone])} rel="noreferrer">JPYC Prepaid を購入する</a>
        </div>
        <div className={styles.bigIconWrapper}>
          <img className={styles.topBgIcon} src={topBgIcon} alt="top-top-icon" />
          <img className={styles.topJPYCSpSymbol} src={topJPYCSpSymbol} alt="jpyc-top-icon" />
        </div>
        <p className={clsx([styles.subText, styles.pcNone])}>
        バプリックチェーン上のプリペイド型ステーブルコイン「JPYC Prepaid 」を日本円で購入可能
        </p>
        <a href='https://app.jpyc.jp/' target="_blank" className={clsx([styles.buyJpyc, styles.pcNone])} rel="noreferrer">JPYC Prepaid を購入する</a>
      </div>
    </section>
  )
}

export default IntroductionSection
