import React from "react";
import styles from "./HowtoSection.module.scss";

const HowtoSection = () => {
  return (
    <section className={styles.howto}>
      <div className={styles.howtoBoxWrapper}>
        <div className={styles.howtoMovieBox}>
          {/* <div className={styles.movieBox}> */}
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/v_4aGzKIod4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          {/* </div> */}
          {/* <iframe id="ytplayer" width="640" height="360"
  src="https://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com" style={{marginRight: '40px'}}></iframe> */}
        </div>
        <div className={styles.howtoTypoWrapper}>
          <h3 className={styles.howtoText}>How to buy</h3>
          <h2 className={styles.howtoTitle}>JPYC Prepaid の購入方法をご覧ください</h2>
          <p className={styles.howtoSubText}>
            JPYC Prepaidの購入は、JPYCAppsでご購入できます。動画の手順を参考にJPYC Prepaidを購入しましょう。
          </p>
          <a
            className={styles.howtoGoApps}
            href="https://app.jpyc.jp/"
            target="_blank"
            rel="noreferrer"
          >
            JPYCAppsはこちら
          </a>
        </div>
      </div>
    </section>
  );
};

export default HowtoSection;
