import React from 'react';
import styles from './PromotionSection.module.scss'

const PromotionSection = () => {
  return (
    <section className={styles.promotion}>
      <div className={styles.promotionBox}>
        <h2 className={styles.promotionTitle}>Let’s buy JPYC Prepaid </h2>
        <p className={styles.promotionText}>プリペイド型ステーブルコインを購入したい方はJPYC Appsへ</p>
        <a href='https://app.jpyc.jp/' target='_blank' rel="noreferrer">
          <div className={styles.promotionButton}>
            <p className={styles.promotionButtonText}>JPYCAppsはこちら</p>
          </div>
        </a>
      </div>
    </section>
  )
};

export default PromotionSection;
