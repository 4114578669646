import React from "react";
import styles from "./Footer.module.scss";
import jpycLogo from "../../../images/jpycTopLogo.svg";
import twitter from "../../../images/twitter.svg";
import youtube from "../../../images/youtube.svg";
import facebook from "../../../images/facebook.svg";
import linkedin from "../../../images/linkedin.svg";
import github from "../../../images/github.svg";

import { Link } from "react-router-dom";

const FooterSection = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <img className={styles.jpycLogo} src={jpycLogo} alt="jpyc-logo" />
        <p className={styles.companyAddress}>
          〒100-0004 東京都千代田区大手町1-6-1 大手町ビル4階 FINOLAB内
        </p>
        <div className={styles.socialGroup}>
          <a
            href="https://twitter.com/jcam_official"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialLogo} src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCcllPeUJa1mZa7ipsrmCNqw"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialLogo} src={youtube} alt="youtube" />
          </a>
          <a
            href="https://www.facebook.com/jpycinc"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialLogo} src={facebook} alt="facebook" />
          </a>
          <a
            href="https://www.linkedin.com/company/jpycinc"
            target="_blank"
            rel="noreferrer"
          >
            <img className={styles.socialLogo} src={linkedin} alt="linkedin" />
          </a>
          <a href="https://github.com/jcam1" target="_blank" rel="noreferrer">
            <img className={styles.socialLogo} src={github} alt="github" />
          </a>
        </div>
        <div className={styles.footerListWrapper}>
          <ul className={styles.footerList}>
            <li>
              <a href="https://jpyc.co.jp/" target="_blank" rel="noreferrer">
                会社概要
              </a>
            </li>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/prepaid"
              target="_blank"
              rel="noreferrer"
            >
                JPYC Prepaid 利用規約
              </a>
            </li>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/shohinken"
              target="_blank"
              rel="noreferrer"
            >
                JPYC商品券利用規約
              </a>
            </li>
            {/*
            <li> 
              <Link to="/terms/jpyc-pay/pay-merchant">JPYC Pay 加盟店向け利用規約</Link>
            </li>
            <li>
              <Link to="/terms/jpyc-pay/pay-payment-customer">JPYC Pay 利用者向け利用規約</Link>
            </li>
            */}
            <li>
            <a 
              href="https://jpyc.co.jp/terms/tokushoho#store"
              target="_blank"
              rel="noreferrer"
            >
              特定商取引法に基づく表示
              </a>
            </li>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              プライバシーポリシー
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.footerListWrapper}>
          <ul className={styles.footerList}>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/kessaiho"
              target="_blank"
              rel="noreferrer"
            >
              資金決済法に基づく表示
              </a>
            </li>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/compensation-policy"
              target="_blank"
              rel="noreferrer"
            >
              不正取引に対する補償方針
              </a>
            </li>
            <li>
            <a 
              href="https://jpyc.co.jp/terms/prevention"
              target="_blank"
              rel="noreferrer"
            >
              不正利用防止に関するご協力のお願い
              </a>
            </li>
            <li>
              <a
                href="https://docs.jpyc.jp/"
                target="_blank"
                rel="noreferrer"
              >
                サービスガイド
              </a>
            </li>
            <li>
              <a
                href="https://docs.jpyc.jp/"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ(個人)
              </a>
            </li>
            <li>
              <a
                href="https://jpyc.co.jp/contact"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ(法人)
              </a>
            </li>
          </ul>
        </div>
        <p className={styles.copyRight}>
          © {new Date().getFullYear()} JPYC Inc.
        </p>
      </div>
    </footer>
  );
};

export default FooterSection;
